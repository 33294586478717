import React from "react"
import * as styles from "./all-news.module.scss"
import { AllNewsModuleConfigurationFieldsFragment, NewsListFieldsFragment } from "../../../graphql-types"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import * as inputsStyles from "../layout/inputs.module.scss"
import { format } from "date-fns"
import sanitizeHtml from "sanitize-html"
import * as globalConfig from "../../../config"

import { configureSVGAccessibility } from "../../utils/accessibility"

type RenderProps = {
  news: NewsListFieldsFragment[]
  config: AllNewsModuleConfigurationFieldsFragment
  title: string
}

export function isNewsPublished(news: NewsListFieldsFragment) {
  const todayDate: number = new Date().getTime()
  const publicationDate: number = new Date(news.publicationDate).getTime()
  const publicationStopDate: number = new Date(news.publicationStopDate).getTime()

  if (
    (publicationStopDate && (todayDate < publicationDate || todayDate >= publicationStopDate)) ||
    todayDate < publicationDate
  ) {
    return false
  } else return true
}

const AllNews: React.FC<RenderProps> = ({ news, title, config }) => {
  function buildNewsCard(newsData: NewsListFieldsFragment) {
    const TEXT_MAX_LENGTH: number = 340
    const isEllispisNeeded: boolean = newsData.newsIntroduction.length > TEXT_MAX_LENGTH
    const redirectUrl: string = `/news/${newsData.slug}`
    const categories: string = newsData.categories && newsData.categories.map(e => e.title).join(", ")
    // Error displayed by date-format => RangeError: Use `yyyy` instead of `YYYY` (in `dd/MM/YYYY`) for formatting years to the input
    const dateFormat =
      globalConfig.date_format[globalConfig.locale] === "dd/MM/YYYY"
        ? "dd/MM/yyyy"
        : globalConfig.date_format[globalConfig.locale]
    const publicationDate = format(new Date(newsData.publicationDate), dateFormat)

    return (
      <li key={newsData.id} className={styles.newsCard} aria-labelledby={newsData.title}>
        <div className={styles.newsCardHeader}>
          <div className={styles.publicationDate}>
            {publicationDate} {newsData.categories.length != 0 ? " –" : null}
          </div>
          <div className={styles.categories}>{categories}</div>
        </div>
        <div className={styles.newsCardContent}>
          <div className={styles.newsCardImageBox}>
            {newsData.image.format == "svg" ? (
              <ReactSVG
                src={newsData.image.url}
                className={styles.images}
                beforeInjection={svg => configureSVGAccessibility(svg, "")}
              />
            ) : (
              <img src={newsData.image.url} className={styles.images} alt="" />
            )}
          </div>
          <div className={styles.newsCardDescription}>
            <h2 className={styles.newsTitle}>{newsData.title}</h2>
            <div className={styles.newsCardDescriptionContent}>
              <div
                className={
                  isEllispisNeeded
                    ? `${styles.truncateContent} ${styles.truncateOverflow}`
                    : `${styles.truncateContent}`
                }
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(newsData.newsIntroduction),
                  }}
                />
              </div>
              <a href={redirectUrl} className={`${styles.link} ${inputsStyles.primaryButton}`}>
                {config.newsRedirectLabel}
              </a>
            </div>
          </div>
        </div>
      </li>
    )
  }

  return (
    <ul className={styles.newsCardList} aria-label={title}>
      {news.filter(isNewsPublished).map(buildNewsCard)}
    </ul>
  )
}

export const fragments = graphql`
  fragment AllNewsModuleConfigurationFields on DatoCmsGlobalConfiguration {
    lastNewsLabel
    newsRedirectLabel
  }

  fragment NewsListFields on DatoCmsNews {
    id
    __typename
    title
    slug
    publicationDate
    publicationStopDate
    newsIntroduction
    categories {
      title
    }
    image {
      alt
      url
      format
    }
  }
`

export default AllNews
