import React, { useEffect } from "react"
import {
  AllNewsFieldsFragment,
  AllNewsModuleConfigurationFieldsFragment,
  AroundMeActigraphModuleFieldsFragment,
  ButtonFieldsFragment,
  CustomFormModuleFieldsFragment,
  CustomFormsConfigurationFieldsFragment,
  CustomTextFieldsFragment,
  EmbeddedIframeFieldsFragment,
  FooterMenuFieldsFragment,
  HeaderMenuFieldsFragment,
  HighlightFieldsFragment,
  InfoTrafficDetailModuleFieldsFragment,
  InfoTrafficFieldsFragment,
  InfoTrafficModuleFieldsFragment,
  ItineraryModuleConfigurationFieldsFragment,
  ItineraryModuleFieldsFragment,
  InternalExternalLinkFieldsFragment,
  LePiloteInfoTrafficLinesIconsFieldsFragment,
  LePiloteInfoTrafficModuleFieldsFragment,
  LePiloteItineraryModuleFieldsFragment,
  LePiloteNextTripsSearchModuleFieldsFragment,
  LinesListFieldsFragment,
  LostObjectsFormConfigurationFieldsFragment,
  LostObjectsFormFieldsFragment,
  LostObjectsFormLineFieldsFragment,
  NewsListFieldsFragment,
  NextDeparturesModuleConfigurationFieldsFragment,
  NextDeparturesModuleFieldsFragment,
  OskarPaymentModuleFieldsFragment,
  SchedulesSearchFieldsFragment,
  SchedulesSearchModuleFieldsFragment,
  SimpleTextFieldsFragment,
  SmsAlertFormFieldsFragment,
  SmsAlertInfoTrafficFormConfigurationFieldsFragment,
  TerDakarItinerarySearchModuleFieldsFragment,
  TextWithImageFieldsFragment,
  TextWithVideoFieldsFragment,
  TicketOfficeSearchFieldsFragment,
  TicketOfficeSearchModuleConfigurationFieldsFragment,
  TicketOfficeSearchModuleFieldsFragment,
  TitleBlockFieldsFragment,
  TransportTitleListFieldsFragment,
  TransportTitleSearchFieldsFragment,
  TransportTitleSearchModuleConfigFieldsFragment,
  VianavigoItineraryModuleFieldsFragment,
  VideoOnlyFieldsFragment,
  WebsiteMapModuleFieldsFragment,
} from "../../../graphql-types"
import sanitizeHtml from "sanitize-html"
import * as styles from "./rich-datocms-content.module.scss"
import { graphql } from "gatsby"
import SchedulesSearch from "../schedules-search/schedules-search"
import TransportTitleSearch from "../transport-title-search/transport-title-search"
import TicketOfficeSearch from "../ticket-office-search/ticket-office-search"
import ItineraryModule from "../itinerary-module/itinerary-module"
import NextDeparturesModule from "../next-departures-module/next-departures-module"
import AllNews from "../all-news/all-news"
import InfoTraffic from "../info-traffic/info-traffic"
import CustomForm from "../custom-form/custom-form"
import WebsiteMap from "../website-map/website-map"
import { render } from "datocms-structured-text-to-html-string"
import LostObjectsForm from "../lost-objects/lost-objects-form"
import SmsAlertInfoTrafficForms from "../sms-alert/sms-alert-forms"
import InfoTrafficLePilote from "../info-traffic-le-pilote/info-traffic-le-pilote"
import TitlePage from "../title-page/title-page"
import LePiloteNextTripsSearch from "../le-pilote-next-trips-search/le-pilote-next-trips-search"
import LePiloteItinerary from "../le-pilote-itinerary/le-pilote-itinerary"
import AroundMeActigraph from "../around-me-actigraph/around-me-actigraph"
import OskarPayment from "../oskar-payment-module/oskar-payment"
import InfoTrafficDetailModule from "../info-traffic/info-traffic-detail-module"
import TerDakarItinerarySearchModule from "../ter-dakar-itinerary-search-module/ter-dakar-itinerary-search-module"
import { pushGtmEvent } from "../../gtm/interaction-event"
import Button from "../button/button"
import VianavigoItinerary from "../vianavigo-itinerary/vianavigo-itinerary"
import { useInternalExternalLink } from "../../hooks"

type RichDatoCmsContentUnion = { __typename: string } & (
  | SimpleTextFieldsFragment
  | CustomTextFieldsFragment
  | TextWithImageFieldsFragment
  | TextWithVideoFieldsFragment
  | HighlightFieldsFragment
  | VideoOnlyFieldsFragment
  | EmbeddedIframeFieldsFragment
  | SchedulesSearchFieldsFragment
  | TicketOfficeSearchFieldsFragment
  | TransportTitleSearchFieldsFragment
  | ItineraryModuleFieldsFragment
  | NextDeparturesModuleFieldsFragment
  | TransportTitleListFieldsFragment
  | NewsListFieldsFragment
  | InfoTrafficFieldsFragment
  | AllNewsModuleConfigurationFieldsFragment
  | CustomFormModuleFieldsFragment
  | WebsiteMapModuleFieldsFragment
  | (HeaderMenuFieldsFragment & FooterMenuFieldsFragment)
  | LostObjectsFormFieldsFragment
  | SmsAlertFormFieldsFragment
  | LePiloteInfoTrafficLinesIconsFieldsFragment
  | LePiloteItineraryModuleFieldsFragment
  | AroundMeActigraphModuleFieldsFragment
  | OskarPaymentModuleFieldsFragment
  | InfoTrafficDetailModuleFieldsFragment
  | TerDakarItinerarySearchModuleFieldsFragment
  | ButtonFieldsFragment
  | VianavigoItineraryModuleFieldsFragment
)

type RenderProps = {
  data: RichDatoCmsContentUnion[]
  locale?: string
  defaultLocale?: string
  pageTitle?: string
  schedulesSearchData?: LinesListFieldsFragment[]
  schedulesSearchModuleConfiguration?: SchedulesSearchModuleFieldsFragment
  ticketOfficeSearchData?: TicketOfficeSearchFieldsFragment[]
  ticketOfficeSearchModuleConfiguration?: TicketOfficeSearchModuleConfigurationFieldsFragment
  mapboxToken?: string
  itineraryModuleConfiguration?: ItineraryModuleConfigurationFieldsFragment
  nextDeparturesModuleConfiguration?: NextDeparturesModuleConfigurationFieldsFragment
  transportTitleSearchData?: TransportTitleListFieldsFragment[]
  transportTitleSearchModuleConfiguration?: TransportTitleSearchModuleConfigFieldsFragment
  allNewsData?: NewsListFieldsFragment[]
  allNewsModuleConfiguration?: AllNewsModuleConfigurationFieldsFragment
  infoTrafficData?: InfoTrafficFieldsFragment[]
  customFormsConfiguration?: CustomFormsConfigurationFieldsFragment
  formCaptchaKey?: string
  menuData?: HeaderMenuFieldsFragment & FooterMenuFieldsFragment
  lostObjectsFormLines?: LostObjectsFormLineFieldsFragment[]
  lostObjectsFormConfiguration?: LostObjectsFormConfigurationFieldsFragment
  smsAlertFormConfiguration?: SmsAlertInfoTrafficFormConfigurationFieldsFragment
  lePiloteInfoTrafficLinesIconsData?: LePiloteInfoTrafficLinesIconsFieldsFragment[]
}

const sanitizeHtmlWithImages = content =>
  sanitizeHtml(content, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]).concat(["p"]),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      img: (sanitizeHtml.defaults.allowedAttributes.img || []).concat(["src", "alt", "width", "height", "title"]),
      p: (sanitizeHtml.defaults.allowedAttributes.p || []).concat(["style"]),
      table: (sanitizeHtml.defaults.allowedAttributes.table || []).concat(["style", "border"]),
      tbody: (sanitizeHtml.defaults.allowedAttributes.tbody || []).concat(["style", "width", "height"]),
      tr: (sanitizeHtml.defaults.allowedAttributes.tr || []).concat(["style", "width", "height"]),
      td: (sanitizeHtml.defaults.allowedAttributes.td || []).concat(["style", "width", "height"]),
      span: (sanitizeHtml.defaults.allowedAttributes.span || []).concat(["style", "width", "height"]),
      li: (sanitizeHtml.defaults.allowedAttributes.li || []).concat(["style"]),
      ul: (sanitizeHtml.defaults.allowedAttributes.ul || []).concat(["style"]),
      ol: (sanitizeHtml.defaults.allowedAttributes.ol || []).concat(["style"]),
      blockquote: (sanitizeHtml.defaults.allowedAttributes.blockquote || []).concat(["style"]),
      div: (sanitizeHtml.defaults.allowedAttributes.div || []).concat(["style"]),
      a: (sanitizeHtml.defaults.allowedAttributes.a || []).concat(["href"]),
    },
    allowedSchemesByTag: {
      ...sanitizeHtml.defaults.allowedSchemesByTag,
      a: ["http", "https", "javascript", "mailto"],
    },
    exclusiveFilter: frame =>
      frame.tag === "a" &&
      frame.attribs.href &&
      frame.attribs.href.startsWith("javascript") &&
      !frame.attribs.href.startsWith("javascript:Didomi"),
  })

const customTextRenderOptions = {
  renderInlineRecord({ record, adapter: { renderNode } }) {
    switch (record.__typename) {
      case "DatoCmsDocument":
        return renderNode(
          "a",
          { href: record.document.url },
          sanitizeHtml(record.title, {
            allowedTags: [],
            allowedAttributes: {},
          })
        )
      case "DatoCmsHome":
        return renderNode(
          "a",
          { href: "/" },
          sanitizeHtml(record.title, {
            allowedTags: [],
            allowedAttributes: {},
          })
        )
      default:
        return renderNode(
          "a",
          { href: `/${record.slug}` },
          sanitizeHtml(record.title, {
            allowedTags: [],
            allowedAttributes: {},
          })
        )
    }
  },
  renderLinkToRecord({ record, children, adapter: { renderNode } }) {
    switch (record.__typename) {
      case "DatoCmsHome":
        return renderNode("a", { href: `/` }, record.title)
      case "DatoCmsPage":
        return renderNode("a", { href: `/${record.slug}` }, children)
      case "DatoCmsDocument":
        return renderNode("a", { href: record.document.url }, children)
      default:
        return null
    }
  },
}

const RichDatoCmsContent: React.FC<RenderProps> = ({
  data,
  locale,
  defaultLocale,
  pageTitle,
  schedulesSearchData,
  schedulesSearchModuleConfiguration,
  ticketOfficeSearchData,
  ticketOfficeSearchModuleConfiguration,
  mapboxToken,
  itineraryModuleConfiguration,
  nextDeparturesModuleConfiguration,
  transportTitleSearchData,
  transportTitleSearchModuleConfiguration,
  allNewsData,
  allNewsModuleConfiguration,
  infoTrafficData,
  customFormsConfiguration,
  formCaptchaKey,
  menuData,
  lostObjectsFormLines,
  lostObjectsFormConfiguration,
  smsAlertFormConfiguration,
  lePiloteInfoTrafficLinesIconsData,
}) => {
  useEffect(() => {
    if (window) {
      let elements = document.getElementsByTagName("a")
      let fileExtensionRegexp = new RegExp(
        /^.+\.((pdf)|(jpg)|(jpeg)|(odt)|(xlsx)|(pptx)|(docx)|(xls)|(ppt)|(txt)|(mov)|(wmv)|(m4v)|(flv)|(mp4))$/ /* /[^\.]+$/ */,
        "g"
      )

      for (var i = 0, len = elements.length; i < len; i++) {
        let file = fileExtensionRegexp.test(elements[i].href)
        if (file) {
          elements[i].target = "_blank"
          let extension = elements[i].href.split(".").pop()
          let titlePath = elements[i].href.split(".")
          let title = titlePath[titlePath.length - 2].split("/").pop()
          elements[i].onclick = function () {
            pushGtmEvent("eventga", "Download", "Download-" + extension.toUpperCase(), title)
          }
        }
      }
    }
  }, [])

  return (
    <div className={"container " + styles.richContent}>
      {data.map(content => {
        if (isSimpleText(content)) {
          return (
            <div
              key={content.id}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtmlWithImages(content.content).replace(new RegExp("<p></p>", "g"), "<br>"),
              }}
            />
          )
        } else if (isCustomText(content)) {
          return (
            <div
              key={content.id}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtmlWithImages(render(content.customContent, customTextRenderOptions)),
              }}
            />
          )
        } else if (isTextWithImage(content)) {
          return (
            <div className={styles.textWithImage} key={content.id}>
              <img
                className={styles.image + (content.imagePosition === "Right" ? " " + styles.imageOnRight : "")}
                src={content.image.url}
                alt={content.image.alt}
              />
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtmlWithImages(content.text),
                }}
              />
            </div>
          )
        } else if (isTextWithVideo(content)) {
          return (
            <div
              key={content.id}
              className={styles.textWithVideo + (content.videoPosition === "Right" ? " " + styles.videoOnRight : "")}
            >
              <div>
                {displayVideo(content.title, content.video)}
                <p>
                  <a
                    href={`https://www.youtube.com/watch?v=${content.video.providerUid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {locale === "fr" ? "Voir la vidéo sur YouTube" : "Watch the video on YouTube"}
                  </a>
                </p>
                <p>
                  {content.transcriptionLink && transcriptionLinkUrl(content.transcriptionLink, locale, defaultLocale)}
                </p>
              </div>
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtmlWithImages(content.content),
                }}
              />
            </div>
          )
        } else if (isHighlight(content)) {
          return (
            <a
              key={content.id}
              className={styles.highlight}
              href={content.link}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtmlWithImages(content.content),
              }}
            />
          )
        } else if (isVideoOnly(content)) {
          return (
            <>
              <div className={styles.videoOnly} key={content.id}>
                {displayVideo(content.title, content.video)}
              </div>

              <p>
                <a
                  href={`https://www.youtube.com/watch?v=${content.video.providerUid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {locale === "fr" ? "Voir la vidéo sur YouTube" : "Watch the video on YouTube"}
                </a>
              </p>
              <p>
                {content.transcriptionLink && transcriptionLinkUrl(content.transcriptionLink, locale, defaultLocale)}
              </p>
            </>
          )
        } else if (isEmbeddedIframe(content)) {
          return (
            <iframe
              key={content.id}
              className={styles.embeddedIframe}
              title={content.iframe.name}
              src={content.iframe.url}
              style={parseStyles(content.iframe.style)}
              sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts allow-downloads"
            />
          )
        } else if (isSchedulesSearch(content)) {
          return (
            <SchedulesSearch
              key={content.id}
              lines={schedulesSearchData}
              moduleConfiguration={schedulesSearchModuleConfiguration}
              label={content.label}
            />
          )
        } else if (isTransportTitleSearchModule(content)) {
          return (
            <TransportTitleSearch
              key={content.id}
              label={content.label}
              transportTitleSearchData={transportTitleSearchData}
              transportTitleSearchModuleConfig={transportTitleSearchModuleConfiguration}
            />
          )
        } else if (isAllNewsModule(content)) {
          return <AllNews key={content.id} title={pageTitle} news={allNewsData} config={allNewsModuleConfiguration} />
        } else if (isTicketOfficeSearch(content)) {
          return (
            <TicketOfficeSearch
              key={content.id}
              ticketOffices={ticketOfficeSearchData}
              moduleConfiguration={ticketOfficeSearchModuleConfiguration}
              mapboxToken={mapboxToken}
              label={content.label}
            />
          )
        } else if (isItineraryModule(content)) {
          return (
            <ItineraryModule
              key={content.id}
              moduleConfiguration={itineraryModuleConfiguration}
              label={content.label}
            />
          )
        } else if (isNextDeparturesModule(content)) {
          return (
            <NextDeparturesModule
              key={content.id}
              moduleConfiguration={nextDeparturesModuleConfiguration}
              label={content.label}
            />
          )
        } else if (isInfoTrafficModule(content)) {
          return <InfoTraffic key={content.id}></InfoTraffic>
        } else if (isCustomForm(content)) {
          return (
            <CustomForm
              key={content.id}
              data={content.form}
              locale={locale}
              configuration={customFormsConfiguration}
              formCaptchaKey={formCaptchaKey}
            />
          )
        } else if (isWebsiteMapModule(content)) {
          return <WebsiteMap menuData={menuData} />
        } else if (isLostObjectsFormModule(content)) {
          return (
            <LostObjectsForm
              title={content.title}
              lines={lostObjectsFormLines}
              moduleConfiguration={lostObjectsFormConfiguration}
              formsConfiguration={customFormsConfiguration}
            />
          )
        } else if (isSmsAlertInfoTrafficFormModule(content)) {
          return (
            <SmsAlertInfoTrafficForms
              title={content.title}
              moduleConfiguration={smsAlertFormConfiguration}
              formsConfiguration={customFormsConfiguration}
            />
          )
        } else if (isLePiloteInfoTrafficModule(content)) {
          return (
            <InfoTrafficLePilote
              linesIconsData={lePiloteInfoTrafficLinesIconsData}
              key={content.id}
            ></InfoTrafficLePilote>
          )
        } else if (isTitleBlock(content)) {
          return <TitlePage titleData={content} key={content.id}></TitlePage>
        } else if (isLePiloteNextTripsSearchModule(content)) {
          return (
            <LePiloteNextTripsSearch
              moduleData={content}
              key={content.id}
              linesIconsData={lePiloteInfoTrafficLinesIconsData}
            ></LePiloteNextTripsSearch>
          )
        } else if (isLePiloteItineraryModule(content)) {
          return (
            <LePiloteItinerary
              mapboxToken={mapboxToken}
              data={content}
              linesIconsData={lePiloteInfoTrafficLinesIconsData}
            ></LePiloteItinerary>
          )
        } else if (isAroundMeActigraphModule(content)) {
          return <AroundMeActigraph data={content} key={content.id}></AroundMeActigraph>
        } else if (isOskarPaymentModule(content)) {
          return <OskarPayment data={content} key={content.id}></OskarPayment>
        } else if (isInfoTrafficDetailModule(content)) {
          return <InfoTrafficDetailModule key={content.id}></InfoTrafficDetailModule>
        } else if (isTerDakarItinerarySearchModule(content)) {
          return <TerDakarItinerarySearchModule key={content.id} data={content}></TerDakarItinerarySearchModule>
        } else if (isButton(content)) {
          return <Button key={content.id} data={content}></Button>
        } else if (isVianavigoItineraryModule(content)) {
          return <VianavigoItinerary key={content.id} data={content}></VianavigoItinerary>
        }
      })}
    </div>
  )
}

export default RichDatoCmsContent
function extractFacebookVideoId(videoUrl) {
  if (!videoUrl) return null
  const match = videoUrl.match(/\/videos\/(\d+)\//)
  return match ? match[1] : null
}

function transcriptionLinkUrl(
  transcriptionLink: InternalExternalLinkFieldsFragment,
  locale: string,
  defaultLocale: string
) {
  const link = useInternalExternalLink(transcriptionLink, locale, defaultLocale)
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {locale === "fr" ? "Voir la transcription textuelle" : "Read the textual transcription"}
    </a>
  )
}

function displayVideo(title: string, video: VideoOnlyFieldsFragment["video"] | TextWithVideoFieldsFragment["video"]) {
  if (video.provider === "youtube") {
    return (
      <iframe
        className={styles.video}
        title={`Vidéo YouTube : ${title || "Sans titre"}`}
        src={`https://www.youtube.com/embed/${video.providerUid}?cc_load_policy=1&rel=0`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    )
  }

  if (video.provider === "vimeo") {
    return (
      <iframe
        src={`https://player.vimeo.com/video/${video.providerUid}?texttrack=fr`}
        title={`Vidéo Vimeo : ${title || "Sans titre"}`}
        className={styles.video}
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    )
  }

  if (video.provider === "facebook") {
    const videoId = extractFacebookVideoId(video.url)
    return (
      <iframe
        src={`https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/${video.providerUid}/videos/${videoId}/&show_text=1`}
        title={`Vidéo Facebook : ${title || "Sans titre"}`}
        className={styles.video}
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
        allowFullScreen
      />
    )
  }
}

function parseStyles(stylesToParse: string): { [property: string]: string } {
  return stylesToParse.split(";").reduce((parsedStyles, style) => {
    const splitted = style.split(":")
    if (splitted[0] && splitted[1]) {
      return { ...parsedStyles, [splitted[0].trim()]: splitted[1].trim() }
    } else {
      return parsedStyles
    }
  }, {})
}

function isSimpleText(content: RichDatoCmsContentUnion): content is SimpleTextFieldsFragment {
  return content.__typename === "DatoCmsSimpleText" || content.__typename === "SimpleTextRecord"
}

function isCustomText(content: RichDatoCmsContentUnion): content is CustomTextFieldsFragment {
  return content.__typename === "DatoCmsCustomText" || content.__typename === "CustomTextRecord"
}

function isTextWithImage(content: RichDatoCmsContentUnion): content is TextWithImageFieldsFragment {
  return content.__typename === "DatoCmsTextWithImage" || content.__typename === "TextWithImageRecord"
}

function isTextWithVideo(content: RichDatoCmsContentUnion): content is TextWithVideoFieldsFragment {
  return content.__typename === "DatoCmsTextWithVideo" || content.__typename === "TextWithVideoRecord"
}

function isHighlight(content: RichDatoCmsContentUnion): content is HighlightFieldsFragment {
  return content.__typename === "DatoCmsHighlight"
}

function isVideoOnly(content: RichDatoCmsContentUnion): content is VideoOnlyFieldsFragment {
  return content.__typename === "DatoCmsVideoOnly"
}

function isEmbeddedIframe(content: RichDatoCmsContentUnion): content is EmbeddedIframeFieldsFragment {
  return content.__typename === "DatoCmsEmbeddedIframe"
}

function isTransportTitleSearchModule(content: RichDatoCmsContentUnion): content is TransportTitleSearchFieldsFragment {
  return content.__typename === "DatoCmsTransportTitleSearchModule"
}

function isSchedulesSearch(content: RichDatoCmsContentUnion): content is SchedulesSearchFieldsFragment {
  return content.__typename === "DatoCmsSchedulesSearch"
}

function isTicketOfficeSearch(content: RichDatoCmsContentUnion): content is TicketOfficeSearchModuleFieldsFragment {
  return content.__typename === "DatoCmsTicketOfficeSearchModule"
}

function isItineraryModule(content: RichDatoCmsContentUnion): content is ItineraryModuleFieldsFragment {
  return content.__typename === "DatoCmsItineraryModule"
}

function isNextDeparturesModule(content: RichDatoCmsContentUnion): content is NextDeparturesModuleFieldsFragment {
  return content.__typename === "DatoCmsNextDeparturesModule"
}

function isAllNewsModule(content: RichDatoCmsContentUnion): content is AllNewsFieldsFragment {
  return content.__typename === "DatoCmsAllNews"
}

function isInfoTrafficModule(content: RichDatoCmsContentUnion): content is InfoTrafficModuleFieldsFragment {
  return content.__typename === "DatoCmsInfoTrafficModule"
}

function isCustomForm(content: RichDatoCmsContentUnion): content is CustomFormModuleFieldsFragment {
  return content.__typename === "DatoCmsCustomFormModule"
}

function isWebsiteMapModule(content: RichDatoCmsContentUnion): content is WebsiteMapModuleFieldsFragment {
  return content.__typename === "DatoCmsWebsiteMapModule"
}

function isLostObjectsFormModule(content: RichDatoCmsContentUnion): content is LostObjectsFormFieldsFragment {
  return content.__typename === "DatoCmsLostObjectsForm"
}

function isSmsAlertInfoTrafficFormModule(content: RichDatoCmsContentUnion): content is SmsAlertFormFieldsFragment {
  return content.__typename === "DatoCmsSmsAlertForm"
}

function isLePiloteInfoTrafficModule(
  content: RichDatoCmsContentUnion
): content is LePiloteInfoTrafficModuleFieldsFragment {
  return content.__typename === "DatoCmsLePiloteInfoTrafficModule"
}

function isTitleBlock(content: RichDatoCmsContentUnion): content is TitleBlockFieldsFragment {
  return content.__typename === "DatoCmsTitleBlock"
}

function isLePiloteNextTripsSearchModule(
  content: RichDatoCmsContentUnion
): content is LePiloteNextTripsSearchModuleFieldsFragment {
  return content.__typename === "DatoCmsLePiloteNextTripsSearchModule"
}

function isLePiloteItineraryModule(content: RichDatoCmsContentUnion): content is LePiloteItineraryModuleFieldsFragment {
  return content.__typename === "DatoCmsLePiloteItineraryModule"
}

function isAroundMeActigraphModule(content: RichDatoCmsContentUnion): content is AroundMeActigraphModuleFieldsFragment {
  return content.__typename === "DatoCmsAroundMeActigraphModule"
}

function isOskarPaymentModule(content: RichDatoCmsContentUnion): content is OskarPaymentModuleFieldsFragment {
  return content.__typename === "DatoCmsOskarPaymentModule"
}

function isInfoTrafficDetailModule(content: RichDatoCmsContentUnion): content is InfoTrafficDetailModuleFieldsFragment {
  return content.__typename === "DatoCmsInfoTrafficDetailModule"
}

function isTerDakarItinerarySearchModule(
  content: RichDatoCmsContentUnion
): content is TerDakarItinerarySearchModuleFieldsFragment {
  return content.__typename === "DatoCmsTerDakarItinerarySearchModule"
}

function isButton(content: RichDatoCmsContentUnion): content is ButtonFieldsFragment {
  return content.__typename === "DatoCmsButton"
}

function isVianavigoItineraryModule(
  content: RichDatoCmsContentUnion
): content is VianavigoItineraryModuleFieldsFragment {
  return content.__typename === "DatoCmsVianavigoItineraryModule"
}

export const fragments = graphql`
  fragment SimpleTextFields on DatoCmsSimpleText {
    __typename
    id
    content
  }

  fragment CustomTextFields on DatoCmsCustomText {
    __typename
    id
    customContent {
      blocks
      links {
        __typename
        ... on DatoCmsPage {
          id: originalId
          slug
          title
        }
        ... on DatoCmsHome {
          id: originalId
          title
        }
        ... on DatoCmsDocument {
          id: originalId
          title
          document {
            id
            url
            alt
            title
          }
        }
      }
      value
    }
  }

  fragment TextWithImageFields on DatoCmsTextWithImage {
    __typename
    id
    text
    imagePosition
    image {
      alt
      format
      url
    }
  }

  fragment TextWithVideoFields on DatoCmsTextWithVideo {
    __typename
    id
    content
    videoPosition
    video {
      provider
      providerUid
      height
      width
      url
      thumbnailUrl
    }
    title
    transcriptionLink {
      ...InternalExternalLinkFields
    }
  }

  fragment HighlightFields on DatoCmsHighlight {
    __typename
    id
    link
    content
  }

  fragment VideoOnlyFields on DatoCmsVideoOnly {
    __typename
    id
    video {
      provider
      providerUid
      height
      width
      url
      thumbnailUrl
    }
    title
    transcriptionLink {
      ...InternalExternalLinkFields
    }
  }

  fragment EmbeddedIframeFields on DatoCmsEmbeddedIframe {
    __typename
    id
    iframe {
      name
      url
      style
    }
  }

  fragment SchedulesSearchFields on DatoCmsSchedulesSearch {
    __typename
    id
    label
  }

  fragment TicketOfficeSearchModuleFields on DatoCmsTicketOfficeSearchModule {
    __typename
    id
    label
  }

  fragment ItineraryModuleFields on DatoCmsItineraryModule {
    __typename
    id
    label
  }

  fragment NextDeparturesModuleFields on DatoCmsNextDeparturesModule {
    __typename
    id
    label
  }

  fragment InfoTrafficModuleFields on DatoCmsInfoTrafficModule {
    __typename
    id
    label
  }

  fragment TransportTitleSearchFields on DatoCmsTransportTitleSearchModule {
    __typename
    id
    label
  }

  fragment AllNewsFields on DatoCmsAllNews {
    __typename
    id
  }

  fragment CustomFormModuleFields on DatoCmsCustomFormModule {
    __typename
    id
    form {
      ...CustomFormFields
    }
  }

  fragment WebsiteMapModuleFields on DatoCmsWebsiteMapModule {
    __typename
    id
  }

  fragment LostObjectsFormFields on DatoCmsLostObjectsForm {
    __typename
    id
    title
  }

  fragment SmsAlertFormFields on DatoCmsSmsAlertForm {
    __typename
    id
    title
  }

  fragment LePiloteInfoTrafficModuleFields on DatoCmsLePiloteInfoTrafficModule {
    __typename
    id
    label
  }

  fragment TitleBlockFields on DatoCmsTitleBlock {
    __typename
    id
    titleBlockLink {
      title
      id
      externalLink
      internalLink {
        ... on DatoCmsHome {
          __typename
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsPage {
          __typename
          id
          slug
        }
      }
      textOrImage
      linkText
      linkImage {
        alt
        format
        url
      }
    }
  }

  fragment LePiloteNextTripsSearchModuleFields on DatoCmsLePiloteNextTripsSearchModule {
    __typename
    id
    title
    trippointFieldLabel
    trippointFieldPlaceholder
    searchButtonLabel
    errorMessage
    arrivingMessage
  }

  fragment AroundMeActigraphModuleFields on DatoCmsAroundMeActigraphModule {
    __typename
    id
    src
  }

  fragment LePiloteItineraryModuleFields on DatoCmsLePiloteItineraryModule {
    __typename
    id
    title
    departureLabel
    departurePlaceholder
    arrivalLabel
    arrivalPlaceholder
    micDepartureAltText
    micArrivalAltText
    microphonePictogram {
      url
    }
    geolocationIcon {
      url
      alt
    }
    geolocationLabel
    swapDepartureArrivalIcon {
      url
      alt
    }
    dateLabel
    dateDepartureTypeLabel
    dateArrivalTypeLabel
    calendarIcon {
      url
      alt
    }
    clockIcon {
      url
      alt
    }
    walkIndicationLabel
    preposition
    searchButtonLabel
    fastestItinerary
    shortestItinerary
    mostDirectItinerary
    directionLabel
    lineIndicationLabel
    durationIndicationLabel
    minuteLabel
    destinationIndicationLabel
    positionErrorMessage
    tripAvailabilityErrorMessage
    walkSegmentIcon {
      url
      alt
    }
    itineraryDetailsButtonIcon {
      url
      alt
    }
    mapDepartureMarker {
      url
      alt
    }
    mapArrivalMarker {
      url
      alt
    }
  }

  fragment OskarPaymentModuleFields on DatoCmsOskarPaymentModule {
    __typename
    id
    moduleTag
  }

  fragment InfoTrafficDetailModuleFields on DatoCmsInfoTrafficDetailModule {
    __typename
    id
  }

  fragment TerDakarItinerarySearchModuleFields on DatoCmsTerDakarItinerarySearchModule {
    __typename
    id
    title
    token
  }

  fragment ButtonFields on DatoCmsButton {
    __typename
    id
    title
    isButtonImg
    imgContent {
      alt
      format
      url
    }
    pictogram {
      alt
      format
      url
    }
    color {
      hex
    }
    hoverColor {
      hex
    }
    internalPageLink {
      ... on DatoCmsHome {
        __typename
      }
      ... on DatoCmsPage {
        __typename
        slug
      }
    }
    externalPageLink
  }

  fragment VianavigoItineraryModuleFields on DatoCmsVianavigoItineraryModule {
    __typename
    id
    token
    configurationId
  }
`
