import React from "react"
import { graphql } from "gatsby"
import { HeaderToolBarFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./header-tool-bar.module.scss"

type RenderProps = {
  toolbarData: HeaderToolBarFieldsFragment
  locale: string
  defaultLocale: string
}

const HeaderToolBar: React.FC<RenderProps> = ({ toolbarData, locale, defaultLocale }) => {
  const [searchTerm, setSearchTerm] = React.useState("")

  const handleChange = event => {
    setSearchTerm(event.target.value)
  }

  React.useEffect(() => {}, [searchTerm])

  const focusInput = () => {
    if (searchTerm && searchTerm.length == 0) {
      document.getElementById("searchBar").focus()
    }
  }

  return (
    <div className={styles.headerToolBar}>
      <div className={styles.headerToolBarContent}>
        <div className={styles.searchBarBox}>
          <form action={locale === defaultLocale ? `/search` : `/${locale}/search`} method="get" role="search">
            <input
              id="searchBar"
              type="text"
              name="query"
              placeholder="Vous recherchez..."
              value={searchTerm}
              title={toolbarData.noSearchLabel}
              onChange={handleChange}
            />
            <button type="submit" onClick={focusInput}>
              <svg
                width="100"
                height="100"
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: toolbarData.searchIconColor.hex.slice(0, 7) }}
              >
                <circle cx="18" cy="18" r="15" stroke="currentColor" strokeWidth="3" fill="none" />
                <line x1="28" y1="28" x2="45" y2="45" stroke="currentColor" strokeWidth="3" strokeLinecap="round" />
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export const queryFragments = graphql`
  fragment HeaderToolBarFields on DatoCmsGlobalConfiguration {
    contactLabel
    contactPhoneNumber
    altText
    searchButtonLabel
    searchIconColor {
      hex
    }
    noSearchLabel
    primaryColor {
      hex
    }
    subsidiaryLogo {
      url
      alt
    }
  }
`

export default HeaderToolBar
