import { graphql } from "gatsby"
import React, { useEffect, useRef } from "react"
import { ReactSVG } from "react-svg"
import { CustomBlockExternalPageFieldsFragment, CustomBlockInternalPageFieldsFragment } from "../../../../graphql-types"
import { pushGtmEvent } from "../../../gtm/interaction-event"
import * as inputsStyles from "../../layout/inputs.module.scss"
import * as globalBlockStyles from "../main-blocks/main-blocks.module.scss"
import * as styles from "./custom-block.module.scss"
import { configureSVGAccessibility } from "../../../utils/accessibility"

type RenderProps = {
  block: CustomBlockExternalPageFieldsFragment | CustomBlockInternalPageFieldsFragment
  isHighlighted: boolean
  intersectionRatioCallback: (ratio: number) => void
}

const CustomBlock: React.FC<RenderProps> = ({ block, isHighlighted, intersectionRatioCallback }) => {
  const component = useRef<HTMLElement>()
  const observer = useRef<IntersectionObserver>()
  useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      return
    }
    const options = { threshold: [0.2, 0.4, 0.6, 0.8, 1.0] }
    observer.current = new IntersectionObserver(entries => {
      intersectionRatioCallback(entries[entries.length - 1].intersectionRatio)
    }, options)
    observer.current.observe(component.current)
    return () => observer.current.disconnect()
  }, []) // do this only once, on mount

  return (
    <section
      className={`${styles.block} ${styles.customBlock}
       ${globalBlockStyles.mainBlocksCard}`}
      ref={component}
    >
      <h3 className={styles.title}>{block.title}</h3>
      {block.image.format === "svg" ? (
        <ReactSVG
          src={block.image.url}
          className={styles.svgImage}
          beforeInjection={svg => configureSVGAccessibility(svg, "")}
        />
      ) : (
        <img src={block.image.url} className={styles.image} alt={block.image.alt || ""} />
      )}

      <a
        className={`${styles.link} ${inputsStyles.primaryButton}`}
        href={block.__typename === "DatoCmsCustomBlockExternalPage" ? block.link : block.contentPageLink.slug}
        onClick={() => pushGtmEvent("eventga", "Homepage", "Bloc Principal", block.linkLabel)}
      >
        {block.linkLabel}
      </a>
    </section>
  )
}

export const fragment = graphql`
  fragment CustomBlockExternalPageFields on DatoCmsCustomBlockExternalPage {
    __typename
    id
    title
    image {
      alt
      format
      url
    }
    linkLabel
    link
  }

  fragment CustomBlockInternalPageFields on DatoCmsCustomBlockInternalPage {
    __typename
    id
    title
    image {
      alt
      format
      url
    }
    linkLabel
    contentPageLink {
      slug
    }
  }
`

export default CustomBlock
