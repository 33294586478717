import React from "react"
import * as styles from "./side-block.module.scss"

type RenderProps = {
  image: string
  title: string
  link: string
  isInternal: boolean
}

const SideBlock: React.FC<RenderProps> = ({ image, title, link, isInternal }) => {
  return (
    <a className={styles.secondaryBlock} href={link} target={isInternal ? "_self" : "_blank"}>
      <img src={image} alt={title} className={styles.image} />
      <span className={styles.link}>{title}</span>
    </a>
  )
}

export default SideBlock
