import React from "react"
import {
  LinesListFieldsFragment,
  SchedulesSearchModuleFieldsFragment,
  SchedulesSearchTransportMethodPictogramFragment,
  SchedulesSearchTransportMethodsFieldsFragment,
} from "../../../../graphql-types"
import * as styles from "./schedules-search-results-transport-method.module.scss"
import SchedulesSearchResultsLine from "../results-line/schedules-search-results-line"
import { ReactSVG } from "react-svg"

type Line = LinesListFieldsFragment

type RenderProps = {
  transportMethod: SchedulesSearchTransportMethodsFieldsFragment
  lines: Line[]
  moduleConfiguration: SchedulesSearchModuleFieldsFragment
}

const SchedulesSearchResultsTransportMethod: React.FC<RenderProps> = ({
  transportMethod,
  lines,
  moduleConfiguration,
}) => {
  const groupedByType = groupByLineType(lines)
  return (
    <section className={styles.transportMethodPanel}>
      <h3 className={styles.transportMethodTitle}>
        {displayImage(transportMethod.pictogram)}
        <span>{transportMethod.name}</span>
      </h3>
      <ul className={styles.lineTypeList}>
        {Array.from(groupedByType.values()).map(linesGroup => {
          return (
            <li key={linesGroup[0].lineType.id}>
              <h4 className={styles.lineTypeTitle}>{linesGroup[0].lineType.label}</h4>
              <ul className={styles.linesList}>
                {linesGroup.map(line => (
                  <li key={line.id} className={styles.line}>
                    <SchedulesSearchResultsLine line={line} moduleConfiguration={moduleConfiguration} />
                  </li>
                ))}
              </ul>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

function groupByLineType(lines: Line[]) {
  return lines.reduce((linesByType, line) => {
    const existingEntries = linesByType.get(line.lineType.id) || []
    const newEntries = existingEntries.concat(line)
    linesByType.set(line.lineType.id, newEntries)
    return linesByType
  }, new Map<string, Line[]>())
}

const displayImage = (image: SchedulesSearchTransportMethodPictogramFragment) => {
  if (image.format === "svg") {
    return (
      <ReactSVG
        src={image.url}
        className={styles.pictogram}
        beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
      />
    )
  }

  return <img src={image.url} alt={image.alt || ""} className={styles.pictogram} aria-hidden="true" />
}

export default SchedulesSearchResultsTransportMethod
