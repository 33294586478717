import React, { useMemo, useState } from "react"
import {
  TicketOfficeFormIconFragment,
  TicketOfficeSearchFieldsFragment,
  TicketOfficeSearchModuleConfigurationFieldsFragment,
} from "../../../../graphql-types"
import Autocomplete from "../../autocomplete/autocomplete"
import * as styles from "./ticket-office-search-form.module.scss"
import { ReactSVG } from "react-svg"
import Capsule from "../../transport-title-search/capsule/capsule"

type TicketOffice = TicketOfficeSearchFieldsFragment
type Municipality = TicketOffice["municipality"]
type Category = TicketOffice["category"]

type RenderProps = {
  ticketOffices: TicketOffice[]
  moduleConfiguration: TicketOfficeSearchModuleConfigurationFieldsFragment
  onChange: (value: FormResult) => void
}

export type FormResult = {
  municipality: Municipality | null
  categories: Category[]
}

const TicketOfficeSearchForm: React.FC<RenderProps> = ({ ticketOffices, moduleConfiguration, onChange }) => {
  const [selectedMunicipality, setSelectedMunicipality] = useState(null)

  const municipalities = useMemo(() => extractMunicipalities(ticketOffices), [ticketOffices])

  const categories = useMemo(() => extractCategories(ticketOffices), [ticketOffices])

  const [selectedCategories, setSelectedCategories] = useState(categories)

  function onSelectedMunicipality(municipality) {
    setSelectedMunicipality(municipality)
    onChange({ municipality, categories: selectedCategories })
  }

  function onToggleCategory(categoryToToggle) {
    let newCategories
    if (selectedCategories.some(category => category.id === categoryToToggle.id)) {
      newCategories = selectedCategories.filter(category => category.id !== categoryToToggle.id)
    } else {
      newCategories = [...selectedCategories, categoryToToggle]
    }
    setSelectedCategories(newCategories)
    onChange({ municipality: selectedMunicipality, categories: newCategories })
  }

  return (
    <form>
      <div className={styles.searchFormFields}>
        {/* Municipalities */}
        <div className={styles.formPart}>
          <div className={styles.field}>
            <label htmlFor="municipalityInput" className={styles.label}>
              {displayImage(moduleConfiguration.municipalityIcon)}
              <span>{moduleConfiguration.municipalityLabel}</span>
            </label>
            <Autocomplete
              onChange={onSelectedMunicipality}
              suggestFunction={query => searchMunicipalities(query, municipalities)}
              toLabel={municipality => (municipality ? municipality.name : "")}
              id="municipalityInput"
              placeholder={moduleConfiguration.municipalityPlaceholder}
              title={moduleConfiguration.municipalityPlaceholder}
            />
          </div>
        </div>

        {/* Categories */}
        <div className={styles.formPart}>
          <fieldset className={styles.field}>
            <legend className={styles.label}>
              {displayImage(moduleConfiguration.categoryIcon)}
              <span>{moduleConfiguration.categoryLabel}</span>
            </legend>
            <ul className={styles.categories}>
              {categories.map(category => (
                <li key={category.id} className={styles.category}>
                  <Capsule
                    label={category.name}
                    handleClick={() => onToggleCategory(category)}
                    filters={selectedCategories.map(item => item.name)}
                  />
                </li>
              ))}
            </ul>
          </fieldset>
        </div>
      </div>
    </form>
  )
}

function extractMunicipalities(ticketOffices: TicketOffice[]): Municipality[] {
  return Array.from(
    ticketOffices
      .reduce((municipalitiesMap, ticketOffice) => {
        municipalitiesMap.set(ticketOffice.municipality.id, ticketOffice.municipality)
        return municipalitiesMap
      }, new Map<string, Municipality>())
      .values()
  )
}

function extractCategories(ticketOffices: TicketOffice[]): Category[] {
  return Array.from(
    ticketOffices
      .reduce((categoriesMap, ticketOffice) => {
        categoriesMap.set(ticketOffice.category.id, ticketOffice.category)
        return categoriesMap
      }, new Map<string, Category>())
      .values()
  )
}

const displayImage = (image: TicketOfficeFormIconFragment) => {
  if (image.format === "svg") {
    return (
      <ReactSVG
        src={image.url}
        className={styles.image}
        beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
      />
    )
  }

  return <img src={image.url} className={styles.image} alt={image.alt || ""} aria-hidden="true" />
}

function searchMunicipalities(query: string, municipalities: Municipality[]): Municipality[] {
  if (!query || query == undefined) {
    query = ""
  }
  return municipalities.filter(municipality => municipality.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
}

export default TicketOfficeSearchForm
