import React, { useState } from "react"
import { ButtonFieldsFragment } from "../../../graphql-types"
import * as styles from "./button.module.scss"
import { graphql } from "gatsby"
import { ReactSVG } from "react-svg"
import { configureSVGAccessibility } from "../../utils/accessibility"

type RenderProps = {
  data: ButtonFieldsFragment
}

const Button: React.FC<RenderProps> = ({ data }) => {
  const [hoverButton, setHoverButton] = useState(false)

  const renderButton = (
    title,
    pictogram,
    internalLink,
    externalLink,
    color,
    hoverColor,
    isButtonImg,
    imgContent,
    hover,
    setHover
  ) => {
    if (isButtonImg) {
      return (
        <button
          className={styles.buttonContainerImg}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <a
            href={
              internalLink && internalLink[0]
                ? internalLink[0].__typename == "DatoCmsPage"
                  ? `/${internalLink[0].slug}`
                  : "/"
                : externalLink
                  ? externalLink
                  : "#"
            }
            className={!imgContent ? styles.buttonContent : styles.buttonContentImg}
            target={internalLink && internalLink[0] ? "_self" : externalLink ? "_blank" : "_self"}
          >
            {!imgContent ? (
              <></>
            ) : imgContent.format === "svg" ? (
              <ReactSVG
                src={imgContent.url}
                className={styles.btnImage}
                aria-hidden={true}
                beforeInjection={svg => configureSVGAccessibility(svg, imgContent.alt)}
              />
            ) : (
              <img src={imgContent.url} alt={imgContent.alt || ""} className={styles.btnImage} />
            )}
          </a>
        </button>
      )
    } else {
      return (
        <button
          className={styles.buttonContainer}
          style={
            color?.hex && {
              backgroundColor: hover ? hoverColor.hex : color.hex,
              border: hover && `1px solid ${color.hex}`,
            }
          }
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <a
            href={
              internalLink && internalLink[0]
                ? internalLink[0].__typename == "DatoCmsPage"
                  ? `/${internalLink[0].slug}`
                  : "/"
                : externalLink
                  ? externalLink
                  : "#"
            }
            className={styles.buttonContent}
            target={internalLink && internalLink[0] ? "_self" : externalLink ? "_blank" : "_self"}
          >
            {pictogram && displayPictogram(pictogram, color, hoverColor, hover)}
            <div className={styles.textContent}>
              <div className={styles.title} style={{ color: hover ? color?.hex : hoverColor?.hex }}>
                {title}
              </div>
            </div>
          </a>
        </button>
      )
    }
  }

  const displayPictogram = (pictogram, color, color2, hover) => {
    return (
      <div className={styles.content}>
        {pictogram.format === "svg" ? (
          <ReactSVG
            className={styles.pictogram}
            src={pictogram.url}
            aria-hidden={true}
            beforeInjection={svg => {
              svg.querySelector("path").setAttribute("fill", hover ? color.hex : color2.hex)
              configureSVGAccessibility(svg, pictogram.alt)
            }}
          />
        ) : (
          <img src={pictogram.url} alt={pictogram.alt || ""} className={styles.image} aria-hidden={true} />
        )}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderButton(
        data.title,
        data.pictogram,
        data.internalPageLink,
        data.externalPageLink,
        data.color,
        data.hoverColor,
        data.isButtonImg,
        data.imgContent,
        hoverButton,
        setHoverButton
      )}
    </div>
  )
}

export const fragments = graphql`
  fragment ButtonFields on DatoCmsButton {
    __typename
    id
    title
    isButtonImg
    imgContent {
      alt
      format
      url
    }
    pictogram {
      alt
      format
      url
    }
    color {
      hex
    }
    hoverColor {
      hex
    }
    internalPageLink {
      ... on DatoCmsHome {
        __typename
      }
      ... on DatoCmsPage {
        __typename
        slug
      }
    }
    externalPageLink
  }
`
export default Button
