import { graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { useQuerySubscription } from "react-datocms"
import { ReactSVG } from "react-svg"
import * as config from "../../../../config.js"
import {
  DatoCmsInfoTraffic,
  DatoCmsLine,
  SecondaryBlocksLabelFieldsFragment,
  TrafficInfoBlockFieldsFragment,
} from "../../../../graphql-types"
import { infoTrafficBlockPicto } from "../../../../static/images"
import { pushGtmEvent } from "../../../gtm/interaction-event"
import { useIsToday } from "../../../hooks"
import * as inputsStyles from "../../layout/inputs.module.scss"
import * as globalBlockStyles from "../main-blocks/main-blocks.module.scss"
import TrafficBlocks from "./traffic-blocks"
import * as styles from "./traffic-parent-block.module.scss"

export enum SelectedTab {
  NOW = "now",
  TOMORROW = "tomorrow",
}

export type LineRelatedToTraffic = {
  line: DatoCmsLine
  traffic: DatoCmsInfoTraffic[]
}

type LinesParsedByDate = {
  today: LineRelatedToTraffic[]
  tomorrow: LineRelatedToTraffic[]
}

type RenderProps = {
  block: TrafficInfoBlockFieldsFragment
  isHighlighted: boolean
  intersectionRatioCallback: (ratio: number) => void
  labels: SecondaryBlocksLabelFieldsFragment
}

const TrafficParentBlock: React.FC<RenderProps> = ({ block, intersectionRatioCallback, labels }) => {
  const component = useRef<HTMLElement>()
  const observer = useRef<IntersectionObserver>()
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(SelectedTab.NOW)
  const nowBlockRef = useRef<HTMLDivElement>()
  const tomorrowBlockRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      return
    }
    const options = { threshold: [0.2, 0.4, 0.6, 0.8, 1.0] }
    observer.current = new IntersectionObserver(entries => {
      intersectionRatioCallback(entries[entries.length - 1].intersectionRatio)
    }, options)
    observer.current.observe(component.current)
    return () => observer.current.disconnect()
  }, []) // do this only once, on mount

  const [info, setInfo] = useState<LinesParsedByDate>(null)
  const [dataInfo, setDataInfo] = useState(null)
  const [infoBlockToday, setInfoBlockToday] = useState<JSX.Element>(null)
  const [infoBlockTomorrow, setInfoBlockTomorrow] = useState<JSX.Element>(null)

  useEffect(() => {
    if (dataInfo) {
      const parsedInfo = parseInfoTraffic(dataInfo.allInfoTraffics)
      setInfo(parsedInfo)
      const infoTrafficBlockToday = (
        <div className={styles.myContainer} ref={nowBlockRef} tabIndex={-1}>
          <TrafficBlocks
            blocks={parsedInfo["today"]}
            label={labels.secondaryBlocksLabel}
            previousLabel={labels.secondaryBlocksPreviousLabel}
            nextLabel={labels.secondaryBlocksNextLabel}
            closeButtonLabel={block.closeButtonLabel}
          />
        </div>
      )
      setInfoBlockToday(infoTrafficBlockToday)
      const infoTrafficBlocks = (
        <div className={styles.myContainer} ref={tomorrowBlockRef} tabIndex={-1}>
          <TrafficBlocks
            blocks={parsedInfo["tomorrow"]}
            label={labels.secondaryBlocksLabel}
            previousLabel={labels.secondaryBlocksPreviousLabel}
            nextLabel={labels.secondaryBlocksNextLabel}
            closeButtonLabel={block.closeButtonLabel}
          />
        </div>
      )
      setInfoBlockTomorrow(infoTrafficBlocks)
    }
  }, [dataInfo])

  const { data } = useQuerySubscription({
    enabled: true,
    query: `
      query infoQuery {
        allInfoTraffics(locale: ${config.locale}, orderBy: [end_DESC]) {
          start
          end
          id
          title
          disruptionType
          displayInHome
          pictogram {
            alt
            format
            width
            height
            url
          }
          lines {
            id
            number
            name
            pictogram {
              alt
              format
              width
              height
              url
            }
          }
          shortDescription
          slug
        }
      }`,
    token: config.readOnlyApi,
  })

  if (data) {
    if (data !== dataInfo) {
      setDataInfo(data)
    }
  }

  const handleTabChange = tabValue => {
    if (tabValue == "now") {
      setSelectedTab(SelectedTab.NOW)
      if (typeof window !== "undefined") {
        setTimeout(() => {
          nowBlockRef.current && nowBlockRef.current.focus()
        })
      }
    } else {
      setSelectedTab(SelectedTab.TOMORROW)
      if (typeof window !== "undefined") {
        setTimeout(() => {
          tomorrowBlockRef.current && tomorrowBlockRef.current.focus()
        })
      }
    }
  }

  const parseInfoTraffic = (dataToParse: DatoCmsInfoTraffic[]): LinesParsedByDate => {
    const lines: DatoCmsLine[] = []
    dataToParse.forEach(infoTraffic => {
      infoTraffic.lines.forEach(line => {
        lines.push(line)
      })
    })

    const uniqueLines = lines.filter((ele, index) => index === lines.findIndex(elem => elem.id === ele.id))
    const myLinesWithTrafficToday: LineRelatedToTraffic[] = []
    const myLinesWithTrafficTomorrow: LineRelatedToTraffic[] = []

    uniqueLines.forEach(line => {
      const infoTrafficArrayToday: DatoCmsInfoTraffic[] = []
      const infoTrafficArrayTomorrow: DatoCmsInfoTraffic[] = []
      dataToParse.forEach(infoTraffic => {
        infoTraffic.lines.forEach(subline => {
          if (line.name === subline.name) {
            if (useIsToday(infoTraffic.start, infoTraffic.end)) {
              infoTrafficArrayToday.push(infoTraffic)
              myLinesWithTrafficToday.push({
                line: line,
                traffic: infoTrafficArrayToday,
              })
            }
            if (isTomorrow(infoTraffic.start, infoTraffic.end)) {
              infoTrafficArrayTomorrow.push(infoTraffic)
              myLinesWithTrafficTomorrow.push({
                line: line,
                traffic: infoTrafficArrayTomorrow,
              })
            }
          }
        })
      })
    })

    const uniqueTrafficEntriesToday = deleteDuplicates(myLinesWithTrafficToday)
    const uniqueTrafficEntriesTomorrow = deleteDuplicates(myLinesWithTrafficTomorrow)
    return {
      today: uniqueTrafficEntriesToday,
      tomorrow: uniqueTrafficEntriesTomorrow,
    }
  }

  const deleteDuplicates = (linesArray: LineRelatedToTraffic[]) =>
    linesArray.filter((ele, index) => index === linesArray.findIndex(elem => elem.line === ele.line))

  const isTomorrow = (min, max?) => {
    if (max) {
      const date = new Date()
      const tomorrowEnd = new Date(date.getTime() + 24 * 60 * 60 * 1000)
      tomorrowEnd.setHours(23, 59, 59, 999)
      const tomorrowStart = new Date(date.getTime() + 24 * 60 * 60 * 1000)
      tomorrowStart.setHours(0, 0, 0, 0)
      const start = new Date(min)
      const end = new Date(max)
      return start.getTime() < tomorrowEnd.getTime() && end.getTime() > tomorrowStart.getTime()
    } else {
      const date = new Date()
      const tomorrow = new Date(date.getTime() + 24 * 60 * 60 * 1000)
      tomorrow.setHours(23, 59, 59, 999)
      const start = new Date(min)
      return tomorrow.getTime() >= start.getTime()
    }
  }

  return (info && info.today.length > 0) || (info && info.tomorrow.length > 0) ? (
    <section className={`${styles.block} ${styles.customBlock} ${globalBlockStyles.mainBlocksCard}`} ref={component}>
      <div className={styles.blockHeader}>
        <ReactSVG
          src={infoTrafficBlockPicto}
          className={styles.blockPicto}
          beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
        />
        <h3 className={styles.title}>{block.title}</h3>
      </div>
      <div className={styles.tabButtonsContainer}>
        <button
          aria-pressed={selectedTab === SelectedTab.NOW}
          className={selectedTab === SelectedTab.NOW ? styles.selectedButton : styles.unselectedButton}
          onClick={() => handleTabChange(SelectedTab.NOW)}
        >
          {block.firstTab}
        </button>
        <button
          aria-pressed={selectedTab === SelectedTab.TOMORROW}
          className={selectedTab === SelectedTab.TOMORROW ? styles.selectedButton : styles.unselectedButton}
          onClick={() => handleTabChange(SelectedTab.TOMORROW)}
        >
          {block.secondTab}
        </button>
      </div>
      {selectedTab === SelectedTab.NOW && <div className={styles.infoTrafficBlock}>{infoBlockToday}</div>}
      {selectedTab === SelectedTab.TOMORROW && <div className={styles.infoTrafficBlock}>{infoBlockTomorrow}</div>}
      <p className={styles.trafficStatusLabel}>{block.disruptedTrafficLabel}</p>
      <a
        className={`${styles.link} ${styles.linkTraffic} ${inputsStyles.primaryButton}`}
        href={block.internalLink.slug}
        onClick={() =>
          pushGtmEvent(
            "eventga",
            "Homepage",
            "Infos Trafic - Plus d'Infos",
            (selectedTab === SelectedTab.NOW && block.firstTab) ||
              (selectedTab === SelectedTab.TOMORROW && block.secondTab)
          )
        }
      >
        {block.linkLabel}
      </a>
    </section>
  ) : (
    <section className={`${styles.block} ${styles.customBlock}`} ref={component}>
      <h3 className={styles.title}>{block.title}</h3>
      {block.image.format === "svg" ? (
        <ReactSVG
          src={block.image.url}
          className={styles.svgImage}
          beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
        />
      ) : (
        <img src={block.image.url} className={styles.image} alt={block.image.alt || ""} />
      )}
      <div className={styles.trafficStatusLabel}>{block.normalTrafficLabel}</div>
      <a
        className={`${styles.link} ${inputsStyles.primaryButton}`}
        href={block.internalLink.slug}
        onClick={() =>
          pushGtmEvent(
            "eventga",
            "Homepage",
            "Infos Trafic - Plus d'Infos",
            (selectedTab == SelectedTab.NOW && block.firstTab) ||
              (selectedTab == SelectedTab.TOMORROW && block.secondTab)
          )
        }
      >
        {block.linkLabel}
      </a>
    </section>
  )
}

export const fragments = graphql`
  fragment TrafficInfoBlockFields on DatoCmsTrafficInfoBlock {
    __typename
    id
    title
    linkLabel
    internalLink {
      slug
    }
    firstTab
    secondTab
    disruptedTrafficLabel
    normalTrafficLabel
    closeButtonLabel
    image {
      alt
      format
      url
    }
  }
`

export default TrafficParentBlock
